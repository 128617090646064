import React, { Suspense } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '/src/components/layout/checkout'
import { CartForm } from '/src/components/commerce/form'
import { Loading } from '/src/components/loading'

const CartPage = () => {
  return (
    <Layout>
      <Row className="mt-5">
        <Col xs={12}>
          <Suspense fallback={<Loading />}>
            <CartForm />
          </Suspense>
        </Col>
      </Row>
    </Layout>
  )
}

export default CartPage

export const Head = () => <title>Cart | Orrcon Steel</title>
